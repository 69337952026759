<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">합체</v-btn>
      <!-- <v-btn
        v-if="teamInfo.isDelete"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn> -->
      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>
      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid>
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12">
              <v-alert
                border="bottom"
                color="info"
                outlined
                dense
                class="ma-0 "
              >
                <div class="text-body-2">
                  <v-icon small color="info">info</v-icon>
                  새로운 카테고리명을 사용하려면 신규등록을 선행하십시오.
                </div>
                <div class="text-body-2">
                  <v-icon small color="info">info</v-icon>
                  기존 카테고리는 자동으로 삭제됩니다.
                </div>
                <div class="text-body-2">
                  <v-icon small color="info">info</v-icon>
                  합체 결과는 연계된 기존 정보에도 자동반영됩니다.
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" class="mt-3">
              <h4>선택 카테고리</h4>
              <v-card
                outlined
                elevation="0"
                max-height="40"
                min-height="40"
                class="text-body-2 pa-1"
              >
                <v-chip
                  v-show="selectedCate.isView"
                  color="white"
                  class="ma-0"
                  close
                  @click:close="delSelected"
                >
                  <v-avatar left>
                    <v-icon small>mdi-collapse-all</v-icon>
                  </v-avatar>
                  {{ selectedCate.title }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col cols="12" class="mt-3">
              <h4 class="mt-1">합체대상</h4>
              <v-card
                outlined
                elevation="0"
                max-height="180"
                min-height="100"
                class="overflow-auto text-body-2 pa-1"
              >
                <v-row
                  v-for="(s, i) in selected"
                  :key="i"
                  no-gutters
                >
                  <v-col cols="10">
                    <v-chip
                      color="white"
                      class="ma-0"
                      @click="addSelected(s, i)"
                    >
                      <v-avatar left>
                        <v-icon small>mdi-checkbox-marked-circle</v-icon>
                      </v-avatar>
                      {{ s.title }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 구분: for Form
    form: {
      pId: 0,
      pNum: 0,
      mergeCate: '',
      delCates: [],
      newSub: ''
    },
    scs: [], // 넘어온 선택 카테고리,
    selectedCate: { // 선택한 합체의 주체 카테고리
      title: '',
      isView: false
    },
    type1: 0,
    parent: null
  }),

  mounted () {
    // this.$validator.localize('ko', this.dictionary)
  },

  computed: {
    selected: { // !! 선택된 카테고리의 래핑
      get: function () {
        return this.scs
      },
      set: function (v) {
        this.scs = v
      }
    }
  },

  methods: {
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 초기화 함수
    async initDialog () {
      this.form.pId = 0
      this.form.pNum = 0
      this.form.mergeCate = ''
      this.form.delCates = []
      this.form.newSub = ''

      this.parent = null // 부모 초기화
      this.selected = [] // 선택한 카테고리 배열 초기화

      // 선택한 합체의 주체 카테고리
      this.selectedCate.title = ''
      this.selectedCate.isView = false
    },
    async open (title, options, parent, cates) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        await this.initDialog()

        // 부모인 관리그룹의 객체
        this.parent = parent
        // 선택 카테고리 배열
        this.selected = cates

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        if (!this.selectedCate.title) {
          throw new Error(`합체할 카테고리를 선택하시기 바랍니다.`)
        }

        // 합체될 카테고리
        const mergeCate = this.selectedCate.title
        // 합체대상 카테고리 배열(삭제대상인 카테고리) - newCate 를 제외한 것
        const delCates = this.selected.map(c => c.title).filter(c => c !== mergeCate)
        // 합체전의 부모의 모든 서브 카테고리 배열
        const tmpSub = this.parent.sub.split('|')
        // 기존 서브카테고리에서 삭제될 카테고리를 제외한 새로운 서브 카테고리 문자열
        const newSub = tmpSub.filter(c => !delCates.includes(c)).join('|')

        // 넘길 폼값을 만든다
        this.form.pId = this.parent.id
        this.form.pNum = this.parent.num
        this.form.mergeCate = mergeCate
        this.form.delCates = delCates
        this.form.newSub = newSub

        // DB 처리
        const { data } = await this.$axios.post('admin/cate/mergeCate01', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    async cancel () {
      try {
        // 다이얼로그를 닫으면서 초기화 해야 한다!
        await this.initDialog()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 선택 추가
    async addSelected (s, i) {
      try {
        if (this.selectedCate.isView) { // 이미 선택한 넘이 있으면 선택 해제
          this.delSelected()
        }
        this.selectedCate.title = s.title
        this.selectedCate.isView = true
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 선택 삭제
    async delSelected () {
      try {
        // this.selectedCate 초기화
        this.selectedCate.title = ''
        this.selectedCate.isView = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
